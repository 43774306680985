import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import HubLogoLight from "../assets/images/hub-logo-white.svg";
import HubLogoDark from "../assets/images/hub-logo.svg";
import SearchBar from "../components/SearchBar";
import Home from "./Home";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";
import { history } from "instantsearch.js/es/lib/routers";
import SearchResults from "./SearchResults";
import { useOktaAuth } from "@okta/okta-react";
import api from "./api";
import utils from "./utils";

const BannerStyles = styled.div`
  background: ${(props) => (props.bannerColor ? props.bannerColor : "#000")};
  text-align: center;
  width: 100%;

  .the-banner {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    @media only screen and (min-width: 800px) {
      margin-left: ${(props) =>
        props.sideBarToggle === "open" ? "286px" : "100px"};
    }
    p {
      cursor: pointer;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      padding: 10px;
      margin: 0 auto;
    }
  }

  &#three-ps {
    background: #2f73da;

    p {
      padding: 5px 10px;
      font-size: 16px;
      font-weight: 600;
      text-transform: none;

      b {
        font-weight: 800;
        font-size: 18;
      }
    }
  }
`;

const Content = styled.div`
  margin-left: ${(props) => (props.noMarginPadding ? 0 : 330)}px;
  ${(props) => props.noMarginPadding && "padding-left: 286px"};
  ${(props) => props.noMarginPadding && "box-sizing: border-box"};
  width: 100%;
  min-height: 100vh;
  position: relative;
  max-width: ${(props) => (props.fullWidthContent ? `100%` : "2000px")};
  display: block;

  @media only screen and (max-width: 1600px) {
    margin-left: ${(props) => (props.noMarginPadding ? 0 : 330)}px;
    width: 100%;
  }
  @media only screen and (max-width: 1300px) {
    margin-left: ${(props) => (props.noMarginPadding ? 0 : 125)}px;
    width: 100%;
  }

  @media only screen and (max-width: 900px) {
    ${(props) => props.noMarginPadding && "padding-left: 100px"};
    width: 100%;
    padding-right: ${(props) => (props.noMarginPadding ? 0 : 40)}px;
    box-sizing: border-box;
    overflow: hidden;
  }

  @media only screen and (max-width: 800px) {
    padding: 0;
    margin: 0 auto;
  }

  &.hidden {
    display: none;
  }

  &.searching {
    background: ${({ theme }) => theme.backgroundColor};
  }

  #leaving-the-hub-message {
    position: fixed;
    top: 0;
    right: 100vw;
    left: 0;
    margin: 0 auto;
    width: 100vw;
    height: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    z-index: 100;
    text-align: center;

    @media (max-width: 900px) {
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
    }

    & > div {
      border: 4px solid #fff;
      margin-top: 50px;
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      margin-top: 40vh;
      padding: 40px;

      @media (max-width: 900px) {
        width: 60%;
      }
    }

    h2 {
      margin: 0;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      padding-top: 5px;

      font-size: 14px;
    }
    a {
      color: ${({ theme }) => theme.blue};
    }
  }

  &.sidebar-closed {
    @media only screen and (min-width: 900px) {
      ${(props) => props.noMarginPadding && "padding-left: 100px"};
    }
  }
`;

const PageWrap = styled.div`
  display: flex;
`;
const MobileTopBar = styled.div`
  width: 100%;
  padding: 10px;
  box-shadow: 3px 3px 9px 1px rgba(165, 165, 165, 0.2);
  display: none;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 20;
  background: ${({ theme }) => theme.backgroundColor};

  @media only screen and (max-width: 800px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    padding-left: 10px;
    width: 100px;
  }

  .hamburger {
    width: 35px;
    height: 30px;
    margin: 10px 10px;
    position: relative;
    cursor: pointer;
    display: inline-block;

    span {
      background-color: ${({ theme }) => theme.textColor};
      position: absolute;
      border-radius: 2px;
      /* transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4); */
      width: 100%;
      height: 4px;
      /* transition-duration: 500ms; */
    }
    span:nth-child(1) {
      top: 0px;
      left: 0px;
    }
    span:nth-child(2) {
      top: 10px;
      left: 0px;
      opacity: 1;
    }
    span:nth-child(3) {
      top: 20px;
      left: 0px;
    }
    /* :not(.open):hover span:nth-child(1) {
      transform: rotate(-3deg) scaleY(1.1);
    }
    :not(.open):hover span:nth-child(2) {
      transform: rotate(3deg) scaleY(1.1);
    }
    :not(.open):hover span:nth-child(3) {
      transform: rotate(-4deg) scaleY(1.1);
    } */
    &.open span:nth-child(1) {
      transform: rotate(45deg);
      top: 13px;
    }
    &.open span:nth-child(2) {
      opacity: 0;
    }
    &.open span:nth-child(3) {
      transform: rotate(-45deg);
      top: 13px;
    }
  }
`;
const Sidebar = styled.aside`
  background: ${({ theme }) => theme.backgroundColor};
  flex-direction: column;
  max-width: 100%;
  width: 286px;
  height: 100vh;
  min-height: 820px;
  position: fixed;
  top: 0;
  z-index: 100;
  transition: width 0.2s ease;

  @media only screen and (max-height: 800px) {
    /* position: unset; */
  }

  &.open {
    @media only screen and (max-width: 1600px) {
      box-shadow: 3px 3px 9px 1px rgba(165, 165, 165, 0.2);
    }
  }

  #sidebar-border {
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100vh;
    background: linear-gradient(180deg, #2f73da 0%, #2dbbef 100%);
  }

  #sidebar-header {
    height: ${(props) => (props.home ? "100px" : "150px")};

    #nav-home-logo {
      margin-left: 20px;
      img {
        margin-top: 20px;
        width: 124px;
        max-width: 100%;
      }
    }
  }

  h3 {
    color: ${({ theme }) => theme.textColor};
    font-size: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }

  nav.menu {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    height: calc(100% - 180px);

    #links {
      opacity: 1;
      transition: opacity 5s ease;
    }

    .menu-item {
      color: ${({ theme }) => theme.textColor};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.9;
      text-transform: uppercase;
      display: block;
      padding: 25px 40px;
      text-decoration: none;
      display: flex;

      p {
        margin: 0;
        opacity: 1;
        transition: all 5s ease 2s;
      }

      &.active {
        color: ${({ theme }) => theme.blue};
      }

      img {
        width: 30px;
        margin-right: 10px;
        filter: ${({ theme }) =>
          theme.name === "light" ? "none" : "brightness(10)"};
      }

      &:hover {
        color: ${({ theme }) => theme.blue};
        /* transform: scale(0.98); */
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }

  #quicklinks {
    position: relative;
    bottom: 30px;

    h2 {
      padding: 5px 40px;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 15px;
      color: ${({ theme }) => theme.textColor};
    }

    a,
    #logout-button {
      padding: 5px 40px;
      margin: 5px 0;
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.textColor};
      text-decoration: none;

      &:hover {
        color: ${({ theme }) => theme.blue};
      }
    }

    #logout-button {
      font-size: 11px;
      margin-top: 30px;
      cursor: pointer;
    }
  }

  #view-toggle {
    padding: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: center;
    cursor: pointer;

    @media only screen and (max-width: 800px) {
      top: 20px;
      right: 10px;
      bottom: unset;
      display: none;
    }

    img {
      width: 10px;
    }
  }

  &.closed {
    width: 100px;

    @media only screen and (max-width: 800px) {
      width: 0;
    }

    #sidebar-header {
      height: auto;

      @media only screen and (max-width: 800px) {
        display: none;
      }

      #nav-home-logo {
        margin-left: 0;
        padding: 0 10px;

        @media only screen and (max-width: 800px) {
          display: none;
        }

        img {
          width: 80px;
        }
      }
      .ais-SearchBox {
        width: 100%;
        // Until we can style this for mobile
        display: none;
      }
    }

    #links {
      @media only screen and (max-width: 800px) {
        display: none;
      }
      .menu-item {
        text-align: center;
        padding: 30px 10px;

        &.active {
          background: ${({ theme }) => theme.hoverBackground};
        }

        p {
          opacity: 0 !important;
          pointer-events: none;
          position: absolute;
        }

        img {
          margin: 0 auto;
        }
      }
    }

    #quicklinks {
      @media only screen and (max-width: 800px) {
        display: none;
      }

      h2 {
        display: none;
      }
      a {
        padding: 10px;
        font-size: 14px;
      }
    }

    #view-toggle {
      img {
        transform: rotate(180deg);
      }
    }
  }
`;

function SideBarPage({
  theme,
  children,
  noSidebar,
  fullWidthContent,
  noMarginPadding,
  home,
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);
  const [sidebarToggleState, setSidebarToggleState] = useState(
    isMobile ? "closed" : "open"
  );
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [announcementsBanner, setAnnouncementsBanner] = useState({});
  const { authState, oktaAuth } = useOktaAuth();
  const [isManager, setIsManager] = useState(false);
  const [algoliaAPIKey, setAlgoliaAPIKey] = useState("");
  const searchClient = algoliasearch("USNYG8KDU5", algoliaAPIKey);

  const adminList = [
    "ali.kirk@mx.com",
    "cameron.bullock@mx.com",
    "tom.kittell@mx.com",
  ];

  async function userCheck() {
    const theUserData = await api.getUserData(oktaAuth);
    const userData = theUserData.userData;
    const apiKey = userData.algoliaKey;
    const isManager = userData.isManager;

    if (!apiKey) {
      console.log("invalid Algolia API Key");
    }

    setIsManager(isManager);
    setAlgoliaAPIKey(apiKey);
  }

  useEffect(() => {
    if (authState.isAuthenticated) {
      // check user for manager and search content permissions
      userCheck();
    }
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchValue(value ? value : "");
    setSearching(value ? true : false);
  };

  useEffect(() => {
    let componentMounted = true;

    async function fetchData() {
      let filterParams = "";
      let results = await api.collection("hub_options", oktaAuth, filterParams);

      if (componentMounted) {
        setAnnouncementsBanner(results);
      }
    }

    fetchData();

    return () => {
      componentMounted = false;
    };
  }, [isManager]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1300;
        if (ismobile !== isMobile) {
          setIsMobile(ismobile);
        }
        if (ismobile) {
          setIsMobile(ismobile);
          setSidebarToggleState("closed");
        } else {
          setSidebarToggleState("open");
        }
      },
      false
    );
  }, [isMobile]);

  const HubLogo = theme.name == "light" ? HubLogoDark : HubLogoLight;

  const toggleSidebar = (e) => {
    if (sidebarToggleState === "open") {
      setSidebarToggleState("closed");
    }
    if (sidebarToggleState === "closed") {
      setSidebarToggleState("open");
    }
  };

  const Banner = () => {
    return (
      <BannerStyles
        bannerColor={announcementsBanner.background_color}
        sideBarToggle={sidebarToggleState}
      >
        <a href={announcementsBanner.banner_link} className="the-banner">
          <p>{announcementsBanner.banner_text}</p>
        </a>
      </BannerStyles>
    );
  };

  const HubLink = ({ text, toLink, icon }) => {
    return (
      <NavLink
        onClick={() => {
          setSearching(false);
          if (isMobile) {
            setSidebarToggleState("closed");
          }
        }}
        to={toLink}
        exact
        className="menu-item"
      >
        <img src={icon} className="menu-item-logo" />

        <p>{text}</p>
      </NavLink>
    );
  };

  const indexName = isManager ? "hub_manager_content" : "Main";
  // const indexName = "hub_test";

  const routing = {
    router: history(),
    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[indexName];
        return {
          q: indexUiState.query,
        };
      },
      routeToState(routeState) {
        return {
          [indexName]: {
            query: routeState.q,
          },
        };
      },
    },
  };

  return (
    <>
      {announcementsBanner.banner && <Banner />}
      <MobileTopBar>
        <a id="nav-home-logo" href="/">
          <img src={HubLogo} alt="hub logo" />
        </a>
        <div
          className={`hamburger ${sidebarToggleState}`}
          onClick={() =>
            setSidebarToggleState(
              sidebarToggleState === "open" ? "closed" : "open"
            )
          }
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </MobileTopBar>
      <PageWrap>
        {algoliaAPIKey && (
          <InstantSearch
            indexName={indexName}
            searchClient={searchClient}
            placeholder="The answers to life the universe and everything"
            routing={routing}
          >
            <Configure analytics={true} hitsPerPage={9} />

            {!noSidebar && (
              <Sidebar home={home} className={`sidebar ${sidebarToggleState}`}>
                <div id="sidebar-border"></div>
                <div id="sidebar-header">
                  <a id="nav-home-logo" href="/">
                    <img src={HubLogo} alt="hub logo" />
                  </a>
                  {!home && algoliaAPIKey && (
                    <SearchBar
                      onSearchInputChange={handleSearchInputChange}
                      className="search-bar"
                    />
                  )}
                </div>

                <nav className="menu">
                  <div id="links">
                    <HubLink
                      icon="https://assets.mx.com/hub/images/sidebar/icon-home.svg"
                      text="Home"
                      toLink="/"
                    />

                    <HubLink
                      icon="https://assets.mx.com/hub/images/sidebar/icon-sidebar-content-library.svg"
                      text="Content Library"
                      toLink="/library/"
                    />

                    <HubLink
                      icon="https://assets.mx.com/hub/images/sidebar/icon-sidebar-cal.svg"
                      text="Events"
                      toLink="/upcoming/"
                    />
                    <HubLink
                      icon="https://assets.mx.com/hub/images/sidebar/Icon-sidebar-resources.png"
                      text="Resources"
                      toLink="/resources/"
                    />
                    {isManager && (
                      <HubLink
                        icon="https://assets.mx.com/hub/images/sidebar/icon-sidebar-life-at-mx.svg"
                        text="Manager Portal"
                        toLink="/manager-portal/"
                      />
                    )}
                  </div>

                  <div id="quicklinks">
                    <h2>Quicklinks</h2>
                    <a
                      href="https://mx.okta.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      OKTA
                    </a>
                    <a
                      href="https://app.tripactions.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Navan
                    </a>
                    <a
                      href="https://www.myworkday.com/mx/d/home.htmld"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Workday
                    </a>
                    <a href="/requests/">Request Portal</a>
                    <a
                      href="https://www.myworkday.com/mx/learning"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MXEDU
                    </a>
                  </div>
                </nav>
                <div id="view-toggle" onClick={(e) => toggleSidebar()}>
                  <img src="https://assets.mx.com/hub/images/icon-arrow-blue.svg" />
                </div>
              </Sidebar>
            )}

            {searching && !home ? (
              <>
                <Content>
                  <SearchResults
                    searchValue={searchValue}
                    isManager={isManager}
                  />
                </Content>
              </>
            ) : (
              <>
                <Content
                  noMarginPadding={noMarginPadding}
                  fullWidthContent={fullWidthContent}
                  className={`content-section ${
                    sidebarToggleState === "closed"
                      ? "sidebar-closed"
                      : "sidebar-open"
                  }`}
                >
                  <>
                    {React.Children.map(children, (child) => {
                      // Clone the child element with the desired props
                      return React.cloneElement(child, { isManager });
                    })}
                    {home && (
                      <>
                        <Home
                          hideSearch={algoliaAPIKey ? false : true}
                          searchValue={searchValue}
                          searching={searching}
                          onSearchInputChange={handleSearchInputChange}
                        />
                      </>
                    )}
                  </>
                </Content>
              </>
            )}
          </InstantSearch>
        )}
      </PageWrap>
    </>
  );
}

export default withTheme(SideBarPage);
