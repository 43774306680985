import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import styled, { withTheme } from "styled-components";
import { useOktaAuth } from "@okta/okta-react";
import HubLogoLight from "../assets/images/hub-logo-white.svg";
import HubLogoDark from "../assets/images/hub-logo.svg";
import {
  useSearchBox,
  InstantSearch,
  SearchBox,
  useHits,
  Highlight,
  Snippet,
  RefinementList,
  ClearRefinements,
  Pagination,
  Configure,
} from "react-instantsearch-hooks-web";
import api from "./api";
import utils from "./utils";

const SearchWrap = ({ hubLogo }) => {
  const { query } = useSearchBox(); // Get current query

  return (
    <StylesWrap className={`${query ? "searching" : "not-searching"}`}>
      <Configure hitsPerPage={10} />
      <div className={`search-container`}>
        <div className="search-header">
          <img src={hubLogo} id="main-hub-logo" />
          <h1>Universal Search</h1>
          <SearchBox type="text" placeholder="Search..." />
        </div>
        <SearchResultsWrap>
          <Results />
        </SearchResultsWrap>
      </div>
    </StylesWrap>
  );
};

// Component to display hits from each index
const Results = () => {
  const { hits } = useHits();
  const { query } = useSearchBox(); // Get current query

  // 🔹 Hide results before searching
  if (!query) return null;

  return (
    <div>
      <div className="filters">
        {hits.length > 0 && (
          <>
            <RefinementList attribute="content_source" />
            <ClearRefinements
              translations={{ resetButtonText: "Uncheck all" }}
            />
          </>
        )}
      </div>
      {hits.length > 0 ? (
        <>
          {/* 🔹 Show Results */}
          <div className="the-results">
            {hits.map((hit) => (
              <a
                key={hit.objectID}
                className="result-item"
                href={hit.url}
                target="_blank"
              >
                {hit.image && (
                  <img
                    className="result-image"
                    src={hit.image}
                    alt={hit.title}
                  />
                )}
                <div className="content">
                  <p className="content-source">{hit.content_source}</p>
                  <p className="content-source">
                    <b>{hit.sub_title}</b>
                  </p>
                  <h4>
                    <Highlight hit={hit} attribute="title" />
                  </h4>
                  <p>{<Snippet attribute="content" hit={hit} />}</p>
                </div>
              </a>
            ))}
          </div>
          <Pagination showFirst={false} showLast={false} />
        </>
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
};

const SuperSearch = ({ theme }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [algoliaAPIKey, setAlgoliaAPIKey] = useState("");
  const [userData, setUserData] = useState(null);

  const HubLogo = theme.name == "light" ? HubLogoDark : HubLogoLight;

  const searchClient = algoliasearch("USNYG8KDU5", algoliaAPIKey);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const oktaUserData = authState.idToken.claims; // Okta user data

      // Try to get existing additional user data from localStorage
      let storedData = JSON.parse(localStorage.getItem("local_user")) || {};

      // Fetch additional user data from your server
      const fetchUserCheck = async () => {
        try {
          const response = await api.getUserData(oktaAuth);

          const additionalData = response.userData;

          const adminList = [
            "ali.kirk@mx.com",
            "cameron.bullock@mx.com",
            "hailey.molina@mx.com",
            "emily.simmons@mx.com",
            "scott.upchurch@mx.com",
            "carrie.johnson@mx.com",
            "chris.leeson@mx.com",
          ];

          // Combine Okta data + server data
          const updatedUserData = {
            ...oktaUserData,
            ...additionalData,
            isAdmin: adminList.includes(oktaUserData.email),
          };

          // Save to localStorage
          // localStorage.setItem("local_user", JSON.stringify(updatedUserData));

          setUserData(updatedUserData);
        } catch (error) {
          console.error("Error fetching additional user data:", error);
        }
      };
      fetchUserCheck();
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (!userData) return;
    setAlgoliaAPIKey(userData.algoliaKey);
  }, [userData]);

  // if (!userData?.isAdmin) {
  //   return (
  //     <StylesWrap className="no-access">
  //       <h1>Page Not Authorized</h1>
  //       <p>Contact Ali Kirk for access</p>
  //     </StylesWrap>
  //   );
  // }

  return (
    <InstantSearch searchClient={searchClient} indexName={"hub_test"}>
      <SearchWrap hubLogo={HubLogo} />
    </InstantSearch>
  );
};

const StylesWrap = styled.div`
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
  box-sizing: border-box;

  .ais-SearchBox-form button {
    display: none;
    width: 100%;
  }
  p {
    color: ${({ theme }) => theme.textColor};
  }

  h1 {
    width: fit-content;
    text-align: center;
    margin: 0 auto 30px auto;
    color: ${({ theme }) => theme.textColor};
    font-weight: 300;
    font-size: 21px;
  }

  &.no-access {
    h1 {
      font-size: 35px;
    }
    p {
      font-size: 18px;
      text-align: center;
      color: ${({ theme }) => theme.textColor};
    }
  }

  .search-header {
    text-align: center;
    #main-hub-logo {
      width: 200px;
      max-width: 100%;
    }

    input {
      height: 40px;
      width: 100%;
      max-width: 100%;
      border-radius: 25px;
      height: 33px;
      padding: 0px 20px;
      border: 1px solid rgb(187, 187, 187);
      background: ${({ theme }) => theme.backgroundColor};
      color: ${({ theme }) => theme.textColor};
    }
  }

  &.not-searching {
    .search-container {
      margin-top: 20%;
      text-align: center;
      width: 100%;
    }
  }

  .filters {
    min-height: 50px;
    h4 {
      margin-bottom: 0;
    }
    button {
      background: transparent;
      border: none;
    }
  }

  .ais-RefinementList {
    border-radius: 15px;
    z-index: 20;
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      list-style: none;
      padding: 0 20px;
      margin: 10px 0 0 0;
      width: 100%;
    }

    li {
      color: ${({ theme }) => theme.textColor};
      margin: 0 7px;
      width: fit-content;

      label {
        align-items: center;
        display: flex;
        flex-direction: row;
      }
    }

    .ais-RefinementList-checkbox {
      margin-right: 10px;
      width: 15px;
      height: 15px;
    }
    .ais-RefinementList-labelText {
      text-transform: capitalize;
      margin-right: 5px;
    }
    .ais-RefinementList-count {
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.blue};
    }
  }

  &:has(.ais-RefinementList-item--selected) button {
    color: ${({ theme }) => theme.blue};
  }

  &.active-dropdown {
    button {
      color: ${({ theme }) => theme.blue};
    }
    .ais-RefinementList {
      display: block;
    }
  }

  .ais-Pagination {
    text-align: center;
    margin-top: 50px;
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 400px) {
        padding: 0;
        margin-bottom: 100px;
      }

      li {
        color: ${({ theme }) => theme.textColor};

        a {
          color: ${({ theme }) => theme.textColor};
          text-decoration: none;
        }
      }
    }
    .ais-Pagination-item {
      font-size: 18px;
    }
    .ais-Pagination-item--selected {
      background: ${({ theme }) => theme.blue};
      border-radius: 50%;
      a {
        color: #fff !important;
      }
    }
    .ais-Pagination-item--firstPage {
      margin-right: 10px;
    }
    .ais-Pagination-item--previousPage {
      margin-right: 10px;
    }
    .ais-Pagination-item--nextPage {
      margin-left: 10px;
    }
    .ais-Pagination-item--lastPage {
      margin-left: 10px;
    }
    .ais-Pagination-item--page {
      width: 30px;
      height: 30px;
      padding: 5px;
      line-height: 1.7;
    }
  }

  button {
    &.filtered {
      background: ${({ theme }) => theme.blue};
      color: #fff;
    }
  }
`;
const SearchResultsWrap = styled.div`
  .the-results {
    width: 100%;
    max-width: 100%;
  }
  .result-item {
    text-decoration: none;
    color: ${({ theme }) => theme.textColor};
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0;

    .content {
      padding-top: 10px;
      margin-left: 30px;
      width: 100%;
      max-width: 100%;

      @media only screen and (max-width: 600px) {
        margin-left: 0;
      }

      p {
        margin: 5px 0 0 0;
      }

      h4 {
        margin: 0;
        font-size: 21px;
        width: 100%;
        max-width: 100%;
      }

      .content-source {
        margin: 0 0 5px 0;
        font-weight: 600;
      }
    }
  }

  .result-image {
    align-self: center;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 15px;
    background: #fff;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
`;

export default withTheme(SuperSearch);
