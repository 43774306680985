import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import parse from "html-react-parser";
import api from "./api";
import CloseIconBlack from "../assets/images/icons/close-black.svg";
import OutlineButton from "../components/OutlineButton";
import utils from "./utils";
import { useOktaAuth } from "@okta/okta-react";
import Button from "./Button";
import Gap from "./gap";

const PostWrapper = styled.div`
  position: relative;
  max-width: 786px;
  width: 100%;
  margin: 50px auto;
  box-sizing: border-box;
  padding: 0 20px 100px 20px;

  @media only screen and (min-width: 1920px) {
    margin: 50px 0;
    padding-left: 80px;
  }

  #article-tags {
    margin: 50px 0 50px 0;
    position: relative;
    padding-top: 3px;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      /* background: linear-gradient(
        90.72deg,
        #6cdbe6 1.62%,
        #2c64ef 69.66%,
        #9c42f6 99.84%
      ); */
      background: ${({ theme }) => theme.blue};
      max-width: 100%;
      width: 786px;
      top: -10px;
    }

    h2 {
      color: ${({ theme }) => theme.textColor};
      margin-bottom: 10px;
    }

    .primary-button {
      margin: 10px 10px 10px 0;
    }
  }

  #directus-btn {
    color: ${({ theme }) => theme.textGray};
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 10px;
    float: right;

    :hover {
      color: ${({ theme }) => theme.blue};
    }
  }
`;

const PostHeader = styled.div`
  padding-bottom: 20px;
  @media (max-width: 1500px) {
    width: 100%;
  }
  h1 {
    font-weight: 700;
    font-size: 45px;
    line-height: 1.2;
    color: ${({ theme }) => theme.textColor};
    margin: 0;
    padding-top: 20px;
  }
  p {
    margin: 30px 0 0 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.textGray};
  }
  img {
    margin-top: 50px;
    height: 300px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  a {
    color: ${({ theme }) => theme.blue};
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.684444px;
    margin-bottom: 20px;
    display: inline-block;
  }
`;

const PostContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  li {
    color: ${({ theme }) => theme.textColor};
    font-family: "Proxima-nova", "SF Pro Text", Helvetica, Arial, sans-serif !important;
  }

  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 1.2;
    margin: 40px 0;
    position: relative;
    margin-bottom: 40px;

    span {
      font-weight: 200 !important;
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      /* background: linear-gradient(
        90.72deg,
        #6cdbe6 1.62%,
        #2c64ef 69.66%,
        #9c42f6 99.84%
      ); */
      background: ${({ theme }) => theme.blue};
      max-width: 100%;
      width: 786px;
      bottom: -10px;
    }
  }

  h3 {
    margin: 10px 0;
    margin-top: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.3;
    text-transform: uppercase;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
    margin: 20px 0;
    color: ${({ theme }) => theme.textColor};

    b {
      font-weight: 700;
    }
    strong {
      font-weight: 700;
    }
  }

  tr {
    height: 40px;
    width: 100%;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table td {
    padding: 15px;
  }
  table p {
    margin: 0;
  }
  table thead td {
    background: ${({ theme }) => theme.sectionColor};
    color: ${({ theme }) => theme.textColor};
    font-weight: bold;
    font-size: 13px;
    border: 1px solid ${({ theme }) => theme.divider};
  }
  table tbody td {
    color: ${({ theme }) => theme.textColor};
    border: 1px solid ${({ theme }) => theme.divider};
  }
  table tbody tr {
    background-color: ${({ theme }) => theme.backgroundColor};
  }
  table tbody tr:nth-child(odd) {
  }

  ul {
    list-style: disc;
    padding-left: 20px;
    li {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.8;
      color: ${({ theme }) => theme.textColor};
      margin: 10px 0;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 20px;
    li {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.8;
      color: ${({ theme }) => theme.textColor};
      margin: 10px 0;
    }
  }

  a {
    color: ${({ theme }) => theme.blue};
  }

  img {
    max-width: 100%;
    align-self: center;
    height: auto;
    margin: 20px auto;
    position: relative;
    z-index: 0;
  }

  tr {
    height: 40px;
  }

  em {
    font-style: italic !important;
  }
  iframe {
    width: 100%;
  }
`;
const ScrollProgressBar = styled.div`
  position: sticky;
  top: 0;
  left: -40px;
  width: ${(props) => (props.percentage >= 0 ? `${props.percentage}%` : "0")};
  background: ${({ theme }) => theme.blue};
  height: 9px;
  z-index: 10;
`;

const Tags = styled.div``;

function Article({ articleType, isManager }) {
  const [post, setPost] = useState({});
  const [suggestMode, setSuggestMode] = useState(false);
  const [content, setContent] = useState("");
  const [scrollProgress, setScrollProgress] = useState(0);
  const [displaySuggestion, setDisplaySuggestion] = useState(false);
  const suggestionRef = useRef("");
  const [oktaInfo, setOktaInfo] = useState([]);
  const postWrapperRef = useRef(null);
  const { authState, oktaAuth } = useOktaAuth();
  const [directorLevel, setDirectorLevel] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const { slug } = useParams();

  function findScrollProgress() {
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;

    setScrollProgress(scrolled);
  }

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", findScrollProgress);
  }

  const adminList = utils.adminList;

  useEffect(() => {
    // Check if the user is authenticated
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);

      // Check Admin to display edit btn
      if (adminList.includes(oktaInfo.email)) {
        setIsAdmin(true);
      }
    }
  }, [oktaInfo]);

  const authenticateDirectusImages = async (articleContent) => {
    const regex = /src\s*=\s*"(.+?)"/g;
    const images = articleContent.match(regex);
    let finalContent = articleContent + "";
    if (!images) {
      return;
    }

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const isDirectusImage = image.includes("directus.hub.mx.com/assets");
      if (!isDirectusImage) continue;

      if (!image.includes("/assets/") || !image.includes("?")) {
        continue;
      }

      const params = image.split("?")[1];
      const linkId = image.split("?")[0].split("/assets/")[1];
      const newImageURL = await api.directusImage(linkId, oktaAuth, params);
      const newSrc = `src="${newImageURL}"`;
      finalContent = finalContent.replaceAll(image, newSrc);
      setContent(finalContent);
    }
  };

  useEffect(() => {
    let componentMounted = true;
    async function fetchLibraryData() {
      let filterParams = "filter[article_url][_eq]=" + slug;
      let post = await api.collection("articles", oktaAuth, filterParams);
      if (post.length === 0) {
        return;
      }

      let articleContent = String(post[0].article_content);

      // sync fields
      post[0].url = `/library/${post[0].article_url}/`;
      post[0].title = post[0].article_title;
      post[0].date = post[0].update_date;
      if (componentMounted) {
        setPost(post[0]);
        setContent(articleContent);
        // authenticateDirectusImages(articleContent);
      }
    }

    async function fetchCommsData() {
      let filterParams = "filter[slug][_eq]=" + slug;
      let post = await api.collection("announcements", oktaAuth, filterParams);
      let articleContent = String(post[0].post_content);

      // sync fields
      post[0].url = `/comms/${post[0].slug}/`;
      post[0].title = post[0].post_title;
      post[0].date = post[0].publish_date;
      if (componentMounted) {
        setPost(post[0]);
        setContent(articleContent);
        // authenticateDirectusImages(articleContent);
      }
    }

    async function fetchCeoData() {
      let filterParams = "filter[url][_eq]=" + slug;
      let post = await api.collection("ceo_corner", oktaAuth, filterParams);
      let articleContent = String(post[0].content);

      // sync fields
      post[0].url = `/${post[0].url}/`;
      post[0].title = post[0].title;
      post[0].date = post[0].publish_date;

      if (componentMounted) {
        setPost(post[0]);
        setContent(articleContent);
        // authenticateDirectusImages(articleContent);
      }
    }
    if (articleType == "ceo") {
      fetchCeoData();
    } else if (articleType == "comms") {
      fetchCommsData();
    } else {
      fetchLibraryData();
    }

    return () => {
      componentMounted = false;
    };
  }, []);

  const handleEditSubmit = () => {
    const data = {
      suggestion: suggestionRef.current.value,
      article: post.id,
      employee: oktaInfo.name,
    };
    async function sendData() {
      let suggestionPost = await api.createItem(
        "article_suggestions",
        oktaAuth,
        data
      );
      if (suggestionPost === "success") {
        closeEditModal();
        //todo thank you / submitted message
      }
    }
    sendData();
  };

  const displayEditModal = () => {
    setDisplaySuggestion(true);
  };

  const closeEditModal = () => {
    setDisplaySuggestion(false);
    setSuggestMode(false);
  };

  let featuredImage =
    "https://directus.hub.mx.com/assets/b4da0650-1312-4f10-8dee-3df4a79c4659";
  if (post.featured_image) {
    featuredImage = `https://directus.hub.mx.com/assets/${post.featured_image}`;
  }

  // Create a more helpful manager message when OKTA server in place

  // if (!isManager) {
  //   return (
  //     <NonManagerMessage>
  //     <h1>Manager Content</h1>
  //     <p>Contact Ali Kirk for access</p>
  //   </NonManagerMessage>
  //     )
  // }

  if (!post.id) {
    return <></>;
  }

  const BackButton = ({ type = "library" }) => {
    let listingPage = "/library";
    console.log(type);

    if (type === "comms" || type === "ceo") {
      listingPage = "/";
    }

    let backText = "Back to Content Library";

    if (type === "comms" || type === "ceo") {
      backText = "Back";
    }

    return <a href={listingPage}>&#60; {backText}</a>;
  };

  const AdminButton = ({ type, post, id = "" }) => {
    let urlPath = "library";

    if (type === "comms" || type === "ceo") {
      urlPath = `https://directus.hub.mx.com/admin/content/announcements/${post.id}`;
    }
    if (type === "ceo") {
      urlPath = `https://directus.hub.mx.com/admin/content/ceo_corner/${post.id}`;
    }

    return (
      <a id="directus-btn" href={urlPath} target="_blank">
        Edit in Directus
      </a>
    );
  };

  return (
    <>
      <ScrollProgressBar percentage={scrollProgress} />
      <PostWrapper ref={postWrapperRef}>
        {post.id && (
          <PostHeader>
            <BackButton type={articleType} />
            {/* {isAdmin && */}
            <AdminButton id="directus-btn" type={articleType} post={post} />
            {/* } */}
            <h1>{post.title}</h1>
            <p>{utils.easyToReadDate(post.date)}</p>
            {post.featured_image && articleType !== "ceo" && (
              <img src={featuredImage} />
            )}
          </PostHeader>
        )}
        <PostContent>{parse(content)}</PostContent>
        {post.article_tags && (
          <Tags id="article-tags">
            <h2>Article Tags</h2>
            {post.article_tags.map((tag, index) => {
              return (
                <Button
                  key={index}
                  href={`/tags/${tag.toLowerCase().replaceAll(" ", "-")}`}
                >
                  {tag}
                </Button>
              );
            })}
          </Tags>
        )}
      </PostWrapper>
    </>
  );
}

export default withTheme(Article);
