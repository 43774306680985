import React, { useState, useEffect, useRef } from "react";
import api from "./api";
import utils from "./utils";
import Button from "./Button";
import { useOktaAuth } from "@okta/okta-react";
import styled, { withTheme, createGlobalStyle } from "styled-components";
import HubLogoLight from "../assets/images/hub-logo-white.svg";
import HubLogoDark from "../assets/images/hub-logo.svg";
import Holidays from "date-holidays";
import PostRow from "../components/PostRow";
import SearchBar from "../components/SearchBar";
import SearchResults from "./SearchResults";
import { GlobalStyles } from "../themes";
import axios from "axios";
import { toInteger } from "lodash";
import HomeCalendar from "./HomeCalendar";
import { post } from "jquery";

var hd = new Holidays("US");

const CustomGlobalStyles = createGlobalStyle`
  body #root{
    background: ${({ theme }) => theme.hoverBackground} !important;
  }

    /* For Chrome, Safari and Edge */
    ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #2f73da;
    border-radius: 10px;
    border: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #2f73da;
  }

  /* For Firefox */
  html {
    scrollbar-width: thin;
    scrollbar-color: #2f73da #f1f1f1;
  }
`;

const PageWrapper = styled.section`
  position: relative;
  background: ${({ theme }) => theme.hoverBackground};
  min-height: 100vh;
  width: 100%;

  h2 {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1;
  }

  p {
    color: ${({ theme }) => theme.textColor};
  }

  #leaving-the-hub-message {
    position: fixed;
    top: 0;
    right: 100vw;
    left: 0;
    margin: 0 auto;
    width: 100vw;
    height: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    z-index: 100;
    text-align: center;

    @media (max-width: 900px) {
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
    }

    & > div {
      border: 4px solid #fff;
      margin-top: 50px;
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      margin-top: 40vh;
      padding: 40px;

      @media (max-width: 900px) {
        width: 60%;
      }
    }

    h2 {
      margin: 0;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      padding-top: 5px;
      font-size: 14px;
    }
    a {
      color: ${({ theme }) => theme.blue};
    }
  }

  #home-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) =>
      theme.name === "light"
        ? "linear-gradient( 90deg, rgba(200, 191, 214, 1) 0%, rgba(248, 249, 251, 1) 50%, rgba(143, 196, 224, 1) 73%, rgba(162, 193, 229, 1) 85%, rgba(255, 255, 255, 1) 96% )"
        : "linear-gradient( 90deg, rgb(105 99 115) 0%, rgb(75 75 75) 50%, rgb(87 123 142) 73%, rgb(91 108 129) 85%, rgb(97 97 97) 96% )"};
    padding: 30px 0;
    width: 100%;

    @media only screen and (max-width: 900px) {
      margin: auto;
    }

    @media only screen and (max-width: 500px) {
      padding: 50px 10px;
      box-sizing: border-box;
    }
    #main-hub-logo {
      margin-bottom: 20px;
      width: 257px;
      max-width: 100%;

      @media only screen and (max-width: 500px) {
        display: none;
      }
    }
  }
  .search-results {
    @media only screen and (max-width: 1600px) {
      padding: 0 40px !important;
      box-sizing: border-box;
    }
  }
`;

const ResponsiveGrid = styled.div`
  padding: 20px 40px;
  box-sizing: border-box;
  display: flex;
  max-width: 2000px;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 800px) {
    padding: 0 10px;
  }

  .column {
    width: 40%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    & > h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.textColor};
    }
  }
  & > .column:first-child {
    width: 60%;
    margin-right: 20px;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      margin-right: auto;
    }
  }
  .grid-cell {
    background: ${({ theme }) => theme.backgroundColor};
    margin: 10px 0;
    border-radius: 15px;

    &#featured-box {
      padding: 20px;
      margin-bottom: 15px;
      text-decoration: none;
      text-align: right;
      flex: 1;
      padding-bottom: 40px;

      h3 {
        text-align: left;
        font-weight: 400;
        font-size: 30px;
        line-height: 1;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        margin: 20px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: ${({ theme }) => theme.textColor};
      }

      p {
        text-align: left;
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        height: auto;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: ${({ theme }) => theme.textColor};
      }

      img {
        border-radius: 5px;
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      .primary-button {
        position: relative;
        font-weight: 400;
        padding: 10px 30px;
        margin-top: 17px;
        font-weight: 400;
        display: inline-block;
      }
    }
    &#company-calendar {
      /* flex: 1; */
    }
    &#events-holidays {
      background: transparent;
      display: flex;
      flex: 1;

      @media only screen and (max-width: 900px) {
        flex-direction: column;
        height: auto;
      }

      .event-item {
        display: flex;
        margin: 10px;

        @media only screen and (max-width: 900px) {
          justify-content: flex-start;
        }

        img {
          width: 50px;
          height: auto;
          object-fit: contain;
        }

        & > div {
          margin: 20px 0;
          margin-left: 15px;
          text-align: left;

          p {
            margin: 0;
            padding-bottom: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            text-transform: capitalize;
            color: ${({ theme }) => theme.textColor};
          }
          h3 {
            margin: 0;
            font-weight: 400;
            font-size: 20px;
            line-height: 1;
            color: ${({ theme }) => theme.textColor};
          }
        }
      }

      .column {
        padding: 0 10px;
        padding-top: 20px;
        border-radius: 15px;
        background: ${({ theme }) => theme.backgroundColor};

        @media only screen and (max-width: 500px) {
          box-sizing: border-box;
          padding-left: 20px;
        }

        &#upcoming-events-wrapper {
          width: 60%;
          max-width: 100%;
          margin-right: 20px;
          padding: 30px 20px 0 20px;
          flex-direction: column;
          justify-content: flex-start;
          height: 500px;
          overflow: auto;

          @media only screen and (max-width: 900px) {
            height: auto;
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 20px;
          }

          .corner-header {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;
            border-bottom: 1px solid ${({ theme }) => theme.textColor};
            padding: 10px 0 20px 0;

            & > div {
              margin: 20px;
            }

            h2 {
              color: ${({ theme }) => theme.textColor};
              font-weight: 700;
              font-size: 30px;
              margin: 0;
            }
            p {
              margin: 0;
              text-align: left;
              color: ${({ theme }) => theme.textColor};
            }

            img {
              width: 100px;
              border-radius: 15px;
            }
          }

          .ceo-corner-post {
            color: ${({ theme }) => theme.textColor};
            transition: all 0.1s ease;
            display: block;
            text-decoration: none;

            &:hover {
              transform: scale(0.98);
            }

            h4 {
              margin: 25px 0 5px 0;
              font-size: 25px;
            }
            p {
              margin: 0;
            }
          }
        }

        &#holiday-box-wrapper {
          max-width: 100%;
          height: 500px;
          overflow: hidden;

          @media only screen and (max-width: 900px) {
            height: auto;
            padding-bottom: 30px;
            width: 100%;
          }

          #holiday-box {
            overflow: auto;
            height: 100%;
            display: flex;
            flex-wrap: wrap;

            @media only screen and (max-width: 900px) {
              overflow: hidden;
              width: 600px;
              max-width: 100%;
              margin: 0 auto;
            }
            @media only screen and (max-width: 600px) {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            .event-item {
              width: 100%;
              text-align: left;
              justify-content: flex-start;

              @media only screen and (max-width: 1450px) {
                justify-content: center;
              }
              @media only screen and (max-width: 600px) {
                justify-content: center;
                text-align: center;
              }

              div {
                width: 60%;
              }
            }
          }
        }
      }
    }
    &#news-list {
      padding: 10px 20px;
      padding-bottom: 15px;
      height: 400px;
      overflow: auto;

      @media only screen and (max-width: 1350px) {
        height: auto;
      }

      h2 {
        text-align: left;
        color: ${({ theme }) => theme.textColor};
      }

      a {
        text-decoration: none !important;
      }
    }
  }
`;

const Content = styled.div`
  width: 1236px;
  max-width: 100%;

  padding: 0 20px;

  @media (max-width: 900px) {
    box-sizing: border-box;
  }
`;

let easyToReadDate = (dateTime, rangeDate) => {
  let date = dateTime.split("T")[0];

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  let dateArr = date.split("-");
  let year = parseInt(dateArr[0]);
  let month = months[parseInt(dateArr[1]) - 1];
  let day = parseInt(dateArr[2]);
  return month + " " + day + ", " + year;
};

function Home({
  theme,
  onSearchInputChange,
  searching,
  searchValue,
  hideSearch,
}) {
  const [feature, setFeature] = useState([]);
  const [ceoPosts, setCeoPosts] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [events, setEvents] = useState([]);
  const { oktaAuth } = useOktaAuth();
  const [theDate, setTheDate] = useState(new Date());
  const [googleCalEventData, setGoogleCalEventData] = useState([]);

  const HubLogo = theme.name == "light" ? HubLogoDark : HubLogoLight;

  let currentYear = new Date().getFullYear();
  const holidayList = hd.getHolidays(currentYear);

  function getHoliday(holiday) {
    if (!holiday) return;
    for (let index = 0; index < holidayList.length; index++) {
      const automatedHoliday = holidayList[index];

      if (holiday == automatedHoliday.name) {
        return automatedHoliday;
      }
    }
  }

  let fullDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const todaysDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  useEffect(() => {
    let componentMounted = true;
    async function fetchData() {
      let filterParams = "filter[status][_eq]=published";
      filterParams += "&filter[home_feature][_eq]=true";
      filterParams += `&limit=1`;
      filterParams += "&sort=-publish_date";
      let featuredPost = await api.collection(
        "announcements",
        oktaAuth,
        filterParams
      );

      let ceoFilterParams = "filter[status][_eq]=published";
      ceoFilterParams += `&limit=5`;
      ceoFilterParams += "&sort=-publish_date";
      let ceoData = await api.collection(
        "ceo_corner",
        oktaAuth,
        ceoFilterParams
      );

      let highlightsFilterParams = "filter[status][_eq]=published";
      highlightsFilterParams += `&limit=5`;
      highlightsFilterParams += "&sort=-publish_date";
      let highlightsData = await api.collection(
        "announcements",
        oktaAuth,
        highlightsFilterParams
      );

      // let eventsFilterParams = `filter[start_date][_gt]=${todaysDate()}`;
      // eventsFilterParams += `&limit=4`;
      // eventsFilterParams += `&sort=start_date`;
      // let eventData = await api.collection(
      //   "events",
      //   oktaAuth,
      //   eventsFilterParams
      // );
      // if (componentMounted) {
      //   setEvents(eventData);
      // }

      let holidayFilterParams = `&sort=start_date`;
      let holidayData = await api.collection(
        "holidays",
        oktaAuth,
        holidayFilterParams
      );

      let upcomingHolidayList = [];

      const checkIfHolidayIsPassed = (holiday) => {
        let expireDate = holiday.start_date;

        if (holiday.end_date) {
          expireDate = holiday.end_date;
        }

        if (todaysDate() <= expireDate) {
          upcomingHolidayList.push(holiday);
        } else {
          return;
        }
      };

      for (let index = 0; index < holidayData.length; index++) {
        const holiday = holidayData[index];
        checkIfHolidayIsPassed(holiday);
      }
      if (componentMounted) {
        setHolidays(upcomingHolidayList);

        setFeature(featuredPost[0]);
        setRecentPosts(highlightsData);
        setCeoPosts(ceoData);
      }
    }

    fetchData();

    return () => {
      componentMounted = false;
    };
  }, []);

  function findTheNextMonth(month) {
    let nextMonth;
    if (month == 12) {
      nextMonth = "01";
      return nextMonth;
    } else {
      nextMonth = month + 1;

      if (nextMonth < 10) {
        nextMonth = "0" + nextMonth;
      }
      return nextMonth;
    }
  }
  const addLeadingZeros = (number) => {
    if (number < 10) {
      return "0" + number;
    } else {
      return number;
    }
  };
  // Google Events
  useEffect(() => {
    (async () => {
      let componentMounted = true;

      async function getGoogleData() {
        const token = await oktaAuth.getIdToken();
        let month = theDate.getMonth() + 1;
        let year = theDate.getFullYear();
        const leadingZerosMonth = month < 10 ? "0" + month : month;

        const baseUrl = "https://api.hub.mx.com";
        // const baseUrl = "http://localhost:3080";

        try {
          const result = await axios.get(
            `${baseUrl}/api/user/calendar/${leadingZerosMonth}/${year}`,
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          );

          function getNextMonthAndYear(currentMonth, currentYear) {
            const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
            const nextYear =
              currentMonth === 12 ? currentYear + 1 : currentYear;
            return { nextMonth, nextYear };
          }

          const nextMonthData = getNextMonthAndYear(month, year);

          const nextMonthsEvents = await axios.get(
            `${baseUrl}/api/user/calendar/${addLeadingZeros(
              nextMonthData.nextMonth
            )}/${nextMonthData.nextYear}`,
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          );

          // remove 3ps from events

          const filterOutEvents = (data) => {
            const keywords = ["3ps", "holiday"]; // Define the keywords to filter out
            return data.filter(function (event) {
              const summary = event.summary.toLowerCase();
              return !keywords.some((keyword) =>
                summary.includes(keyword.toLowerCase())
              );
            });
          };

          const sortByStartDate = (array) => {
            return array.sort((a, b) => {
              const dateA = new Date(a.start);
              const dateB = new Date(b.start);
              return dateA - dateB;
            });
          };

          const filteredNextMonthEvents = filterOutEvents(
            nextMonthsEvents.data
          );
          const filteredCurrentMonthEvents = filterOutEvents(result.data);

          const filteredResult = filteredCurrentMonthEvents;
          const upcomingEventsList = [
            ...filteredCurrentMonthEvents,
            ...filteredNextMonthEvents,
          ];

          const events = upcomingEventsList.map((event) => ({
            id: event.id,
            start_date: event.start.dateTime ? "time" : "all-day",
            start: event.start.dateTime
              ? event.start.dateTime.split("T")[0]
              : event.start.date,
            start_day_date: event.start.dateTime
              ? parseInt(event.start.dateTime.split("T")[0].split("-")[2])
              : parseInt(event.start.date.split("-")[2]),
            title: event.summary.trim(),
            description: event.description,
          }));

          if (componentMounted) {
            let eventsFromToday = [];

            for (let index = 0; index < events.length; index++) {
              const element = events[index];

              let eventYear = parseInt(element.start.split("-")[0]);
              let eventMonthDay =
                element.start.split("-")[1] + element.start.split("-")[2];
              let eventDateCode = `${eventYear}${eventMonthDay}`;

              let todaysYear = theDate.getFullYear();
              let todaysMonthDay =
                String(addLeadingZeros(theDate.getMonth() + 1)) +
                String(addLeadingZeros(theDate.getDate()));
              let todaysDateCode = `${todaysYear}${todaysMonthDay}`;

              // Preventing duplicate event bug
              const isObjectInArray = eventsFromToday.some((item) => {
                return item.title === element.title;
              });

              if (eventDateCode >= todaysDateCode && !isObjectInArray) {
                eventsFromToday.push(element);
              }
            }

            const sortedByDate = sortByStartDate(eventsFromToday);

            setEvents(sortedByDate);
          }
        } catch (err) {
          // Handle error
          console.error("Error fetching events:", err);
        }
      }
      getGoogleData(theDate);

      return () => {
        componentMounted = false;
      };
    })();
  }, [theDate]);

  //rogue easter egg submission from anonymous employee
  useEffect(() => {
    try {
      const today = new Date();
      const todayIsFriday = today.getDay() === 5;
      if (todayIsFriday) {
        document.getElementById("week-4").innerHTML = "Friyay";
      }
    } catch (err) {}
  }, []);

  return (
    <>
      <CustomGlobalStyles />
      <PageWrapper className="home-wrap">
        <>
          <div id="home-header">
            <img src={HubLogo} id="main-hub-logo" />

            {!hideSearch && (
              <SearchBar
                className={`home-search-bar`}
                onSearchInputChange={onSearchInputChange}
              />
            )}
          </div>
        </>

        {searching && !hideSearch && (
          <SearchResults searchValue={searchValue} />
        )}

        {!searching && (
          <ResponsiveGrid>
            <div className="column">
              <div className="grid-cell" id="company-calendar">
                <HomeCalendar />
              </div>

              <div className="grid-cell" id="events-holidays">
                <div className="column" id="upcoming-events-wrapper">
                  <div className="corner-header">
                    <div>
                      <h2>CEO Corner</h2>
                      <p>With Ryan Caldwell</p>
                    </div>
                    <img src="https://assets.mx.com/hub/images/rc-sq.png" />
                    {/* <img src="https://directus.hub.mx.com/assets/e1d8b512-8a73-4d8f-883b-5d1394a97360.png" /> */}
                  </div>
                  {ceoPosts && ceoPosts.length > 0 ? (
                    ceoPosts.map((post) => {
                      return (
                        <a
                          className="ceo-corner-post"
                          href={`/ceo/${post.url}/`}
                          key={post.id}
                        >
                          <h4>{post.title}</h4>
                          <p>{utils.parseHTML(post).slice(0, 360) + "..."}</p>
                        </a>
                      );
                    })
                  ) : (
                    <p>Coming Soon</p>
                  )}
                </div>
                <div className="column" id="holiday-box-wrapper">
                  <h2>Paid Holidays</h2>
                  <div id="holiday-box">
                    {holidays.map((holiday, index) => {
                      let iconUrl =
                        "https://assets.mx.com/hub/images/home/icon-cal.svg";
                      if (holiday.icon) {
                        iconUrl = `https://directus.hub.mx.com/assets/${holiday.icon}`;
                      }
                      return (
                        <div key={index} className="event-item">
                          <img src={iconUrl} />
                          <div>
                            <p>
                              {holiday.start_date &&
                                easyToReadDate(holiday.start_date)}
                              <span>
                                {holiday.end_date &&
                                  holiday.end_date !== holiday.start_date &&
                                  ` - ${easyToReadDate(holiday.end_date, "")}`}
                              </span>
                            </p>
                            <h3>{holiday.name}</h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="grid-cell" id="featured-box">
                {feature.id && (
                  <div id="featured-post">
                    <img
                      src={
                        feature.featured_image
                          ? `https://directus.hub.mx.com/assets/${feature.featured_image}`
                          : "https://directus.hub.mx.com/assets/b4da0650-1312-4f10-8dee-3df4a79c4659"
                      }
                    />
                    <h3>{feature.post_title}</h3>
                    <p>{utils.parseHTML(feature)}</p>
                    <Button href={`/comms/${feature.slug}`}>Read More</Button>
                  </div>
                )}
              </div>
              <div className="grid-cell" id="news-list">
                <h2>Highlighted Information</h2>
                {recentPosts.map((post) => {
                  return (
                    <PostRow
                      key={post.id}
                      postId={post.id}
                      title={post.post_title}
                      description={utils.parseHTML(post)}
                      link={`/comms/${post.slug}/`}
                      postRowType="home"
                      image={post.featured_image}
                    />
                  );
                })}
              </div>
            </div>
          </ResponsiveGrid>
        )}
      </PageWrapper>
    </>
  );
}

export default withTheme(Home);
