import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";

// Styled wrapper with nested styles
const StatusPageWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;

  h1 {
    text-align: center;
    color: ${({ theme }) => theme.textColor};
  }

  .status-list {
    padding: 0;
    & > a {
      text-decoration: none;
      background: ${({ theme }) => theme.sectionColor};
      padding: 20px 15px;
      margin-bottom: 10px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-left: 6px solid transparent;
      color: ${({ theme }) => theme.textColor};

      transition: all 0.2s ease;

      &:hover {
        transform: scale(0.98);
      }
    }
    .status-text {
      font-weight: bold;
      text-transform: capitalize;
      font-size: 16px;
      text-align: right;
      margin-bottom: 5px;
    }

    p {
      color: ${({ theme }) => theme.textColor};
      margin: 0;
    }

    .duration {
      position: relative;
    }

    .status.running {
      border-color: #f39c12;
      .status-text {
        color: #f39c12;
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .loading-spinner {
      animation: spin 1s linear infinite;
      width: 25px; /* Set the size of the spinner */
      height: 25px; /* Set the size of the spinner */
      margin-right: 0;
      opacity: 0.6;
      position: relative;
    right: -20px;
    top: 10px;
}
    }

    .status.success {
      border-color: #2ecc71;
      .status-text {
        color: #2ecc71;
      }
    }

    .status.failed {
      border-color: #e74c3c;
      .status-text {
        color: #e74c3c;
      }
    }

    .status.canceled {
      border-color: #95a5a6;
      .status-text {
        color: #95a5a6;
      }
    }

    .details {
      flex-grow: 1;
      margin-left: 15px;
    }

    .name {
      font-size: 16px;
      font-weight: 600;
    }

    .id {
      font-size: 14px;
    }

    .timestamp {
      margin: 10px 0;
      font-size: 16px;
    }
  }
`;

const formatDateTime = (isoString) => {
  const date = new Date(isoString);

  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

const readableSource = (pipelineData) => {
  const { source, ref } = pipelineData;

  if (source === "push") {
    if (ref === "master") return "Main Branch Push";
    if (ref === "ares") return "Ares Branch Push";
    if (ref === "stable") return "Stable Branch Push";
    return "Feature Branch Push Pipeline"; // Catch-all for other branches
  }

  if (source === "trigger") return "CMS Trigger";
  if (source === "merge_request_event") return "Merge Request";

  return "Unknown Pipeline Type"; // Fallback
};

const pipelineDuration = (createdAt, updatedAt) => {
  const start = new Date(createdAt);
  const end = new Date(updatedAt);
  const durationInSeconds = Math.floor((end - start) / 1000);

  let minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;

  // Round up if more than 30 seconds
  if (seconds >= 30) {
    minutes += 1;
  }

  return `${minutes} minutes`;
};

const baseUrl = "https://api.hub.mx.com";
// const baseUrl = "http://localhost:3080";

const PipelineStatusPage = () => {
  const [pipelines, setPipelines] = useState([]);
  const pollingRef = useRef(null); // Reference to store interval

  const fetchPipelines = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/gitlab-pipelines`);

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      console.log(response.data);

      return response.data;
    } catch (error) {
      console.error("❌ Error fetching pipelines:", error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPipelines();
      setPipelines(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const updatePipelines = async () => {
      const latestPipelines = await fetchPipelines();
      setPipelines(latestPipelines);

      // Check if any pipelines are still running or pending
      const hasPendingPipelines = latestPipelines.some(
        (pipeline) =>
          pipeline.status === "running" || pipeline.status === "pending"
      );

      if (!hasPendingPipelines) {
        clearInterval(pollingRef.current); // Stop polling if no more pending/running pipelines
      }
    };

    updatePipelines(); // Initial fetch
    pollingRef.current = setInterval(updatePipelines, 30000); // Poll every 30 sec

    return () => clearInterval(pollingRef.current); // Cleanup on unmount
  }, []);

  return (
    <StatusPageWrapper>
      <h1>MX.com Pipeline Status</h1>
      <div className="status-list">
        {pipelines.map((pipeline) => (
          <a
            key={pipeline.id}
            className={`status ${pipeline.status}`}
            href={pipeline.web_url}
            target="_blank"
          >
            <div className="details">
              <p className="name">{`${readableSource(pipeline)}`}</p>
              <p className="timestamp">{formatDateTime(pipeline.created_at)}</p>
              <p className="id">{`${pipeline.id}`}</p>
            </div>
            <div className="duration">
              <p className="status-text">{pipeline.status}</p>

              {pipeline.status === "success" &&
                pipelineDuration(pipeline.created_at, pipeline.updated_at)}
              {pipeline.status === "running" && (
                <img
                  className="loading-spinner"
                  src="https://assets.mx.com/hub/images/share/icon-spinner.svg"
                />
              )}
            </div>
          </a>
        ))}
      </div>
    </StatusPageWrapper>
  );
};

export default PipelineStatusPage;
